import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { MedicalFacilityService } from 'src/app/BLL/medical-facility/medical-facility.service';
import { NotificationsService } from 'src/app/BLL/notifications/notifications.service';
import { ToastService } from '../toast/toast.service';
import jwtDecode from 'jwt-decode';
import { UsersService } from 'src/app/BLL/users/users.service';
import { friendlyErrorMessage } from 'src/app/helpers/error-message.helper';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  //Current notification from Firebase
  currentMessage = new BehaviorSubject(null);
  notificationArray: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  userData: any;
  medicalFacility: any;

  constructor(
    private afAuth: AngularFireAuth,
    private messaging: AngularFireMessaging,
    private _medicalFacilityService: MedicalFacilityService,
    private _notificationsService: NotificationsService,
    private _userService: UsersService,
    private _toastService: ToastService
  ) {}

  /**
   * Update the firebase token everytime it permission is granted
   * @param token the messaging token returned from firebase messaging
   */
  updateToken(token: string) {
    this.afAuth.authState.subscribe(async (user) => {
      if (!user) return;

      if (token) {
        localStorage.setItem('device_token', JSON.stringify(token));
        this.userData = JSON.parse(localStorage.getItem('user'));
        //Decode medical facility id from user token
        let medicalFacilityId = jwtDecode(this.userData?.stsTokenManager?.accessToken)[
          'medical_facility_id'
        ];

        //Body to update Medical Facility device token
        let body = {
          fcm_token: token,
        };

        try {
          if (this.userData?.uid) {
            await this._userService.EditUser(
              this.userData?.stsTokenManager?.accessToken,
              this.userData?.uid,
              body
            );
          }
        } catch (error) {
          this._toastService.presentToast(friendlyErrorMessage(error), 'danger');
        }

        // try {
        //   this.medicalFacility = await this._medicalFacilityService.getMedicalFacility(
        //     this.userData?.stsTokenManager?.accessToken,
        //     medicalFacilityId
        //   );

        //   if (this.medicalFacility && this.medicalFacility?.id) {
        //     localStorage.setItem("medical_facility", JSON.stringify(this.medicalFacility));
        //     let result = await this._medicalFacilityService.updateFacility(
        //       this.userData?.stsTokenManager?.accessToken,
        //       this.medicalFacility?.id,
        //       body
        //     );
        //   }
        // } catch (error) {
        //   this._toastService.presentToast(error, "danger");
        // }
      }
    });
  }

  /**
   * Request permission from user to receive notifications
   */
  requestPermission() {
    this.messaging.requestToken.subscribe(
      (token) => {
        // console.log("device_token", token);
        this.updateToken(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * Receive notifications and update the subscription
   */
  receiveMessage() {
    this.messaging.messages.subscribe(async (notification) => {
      // console.log("new message received. ", notification);
      this.currentMessage.next(notification);
      this.updateNotificationArray(notification);
      // let body = {
      //   title: notification["notification"]["title"],
      //   body: notification["notification"]["body"],
      //   deeplink: notification["data"]["deeplink"],
      //   department_id: notification["data"]["department_id"],
      // };
      // try {
      //   this.userData = JSON.parse(localStorage.getItem("user"));
      //   //Decode medical facility id from user token
      //   let medicalFacilityId = jwtDecode(this.userData?.stsTokenManager?.accessToken)["medical_facility_id"];
      //   let response = await this._notificationsService.postNotifications(
      //     this.userData?.stsTokenManager?.accessToken,
      //     medicalFacilityId,
      //     body
      //   );
      // } catch (error) {
      //   this._toastService.presentToast(error, "danger");
      // }
    });
  }

  /**
   * Update the notification array
   * @param notification The new notification
   */
  updateNotificationArray(notification) {
    const currentNotification = this.notificationArray.value;
    // const updatedArray = [...currentNotification, notification];
    const updatedArray = [notification, ...currentNotification];
    console.log('notification array', updatedArray);
    this.notificationArray.next(updatedArray);
  }

  clearNotification(index: number) {
    let currentNotificationArray = this.notificationArray.value;
    currentNotificationArray.splice(index, 1);
    this.notificationArray.next(currentNotificationArray);
  }

  clearAllNotifications() {
    this.notificationArray.next([]);
  }
}

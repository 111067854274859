import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { from, Observable } from "rxjs";
import { exhaustMap, take } from "rxjs/operators";
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AngularFireAuth) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return from(this.auth.currentUser).pipe(
      take(1),
      exhaustMap((user) => {
        if (!user) {
          return next.handle(request);
        }

        return from(user.getIdToken()).pipe(
          take(1),
          exhaustMap((token) => {
            const modifiedRequest = request.clone({
              setHeaders: {
                "Content-Type": "application/json",
                Authorization: token,
              },
            });

            return next.handle(modifiedRequest);
          })
        );
      })
    );
  }
}

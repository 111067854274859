import { Injectable } from "@angular/core";
import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class BlobStorageService {
  constructor() {}

  accountName = environment.blobConfig.accountName;
  containerName = environment.blobConfig.containerName;
  sasToken = environment.blobConfig.sasToken;

  url = `https://${this.accountName}.blob.core.windows.net/${this.containerName}/`;

  public async uploadFiles(content: Blob, name: string) {
    const blockBlobClient = this.containerClient(this.sasToken).getBlockBlobClient(name);
    await blockBlobClient.uploadData(content, {
      blobHTTPHeaders: { blobContentType: content.type },
    });
  }

  public async deleteFile(name: string) {
    await this.containerClient(this.sasToken).deleteBlob(name);
    console.log(name + " deleted");
  }

  private containerClient(sas?: string): ContainerClient {
    let token = "";
    if (sas) {
      token = sas;
    }
    return new BlobServiceClient(`https://${this.accountName}.blob.core.windows.net?${token}`).getContainerClient(
      this.containerName
    );
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

//Data Models
import { User } from './models/signin.model';

//Endpoints
import { urls } from './endpoints/signin.endpoints';

@Injectable({
  providedIn: 'root',
})
export class SigninService {
  constructor(private _apiService: ApiService, private http: HttpClient) {}

  getUser(userId: string, accessToken: string) {
    let queryParam = `?user_id=${userId}`;
    let endPoint = this._apiService.baseURL + urls.getUserByID + queryParam;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .get<User>(endPoint, { headers: new HttpHeaders(headers) })
      .toPromise();
  }
}

import { GetUsers } from '../BLL/users/models/users.model';

/**
 * Current rules for accessing the profile page:
 * * User is a medical facility admin
 * * User has a medical facility manager
 *
 * @returns Boolean of whether or not the user has access to the Medical Facility's profile page.
 */
export const doesUserHaveAccessToMFProfile = (): boolean => {
  const user: GetUsers = JSON.parse(localStorage.getItem('user_db'));

  if (user) {
    const userRoles = ['facility-admin', 'facility-manager'];

    if (userRoles.includes(user.user_role) && user.category_type === 'facility') {
      return true;
    }
  }

  return false;
};

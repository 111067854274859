import { Injectable } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

//Data Models
import { post_notification } from "./models/notifications.model";

//Endpoints
import { urls } from "./endpoints/notifications.endpoints";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(private _apiService: ApiService, private http: HttpClient) {}

  getNotifications(accessToken: string, medicalFacilityId: string) {
    let queryParam = `?medical_facility_id=${medicalFacilityId}`;
    let endPoint = this._apiService.baseURL + urls.notifications + queryParam;

    let headers = {
      "Content-Type": "application/json",
      Authorization: accessToken,
    };

    return this.http.get(endPoint, { headers: new HttpHeaders(headers) }).toPromise();
  }

  postNotifications(accessToken: string, medicalFacilityId: string, body: post_notification) {
    let queryParam = `?medical_facility_id=${medicalFacilityId}`;
    let endPoint = this._apiService.baseURL + urls.notifications + queryParam;

    let headers = {
      "Content-Type": "application/json",
      Authorization: accessToken,
    };

    return this.http
      .post(endPoint, body, {
        headers: new HttpHeaders(headers),
        responseType: "text",
      })
      .toPromise();
  }

  deleteNotificationByMFId(accessToken: string, medicalFacilityId: string) {
    let queryParam = `?medical_facility_id=${medicalFacilityId}`;
    let endPoint = this._apiService.baseURL + urls.notifications + queryParam;

    let headers = {
      "Content-Type": "application/json",
      Authorization: accessToken,
    };

    return this.http
      .delete(endPoint, {
        headers: new HttpHeaders(headers),
        responseType: "text",
      })
      .toPromise();
  }

  deleteNotificationById(accessToken: string, notificationId: string) {
    let queryParam = `?notification_id=${notificationId}`;
    let endPoint = this._apiService.baseURL + urls.notifications + queryParam;

    let headers = {
      "Content-Type": "application/json",
      Authorization: accessToken,
    };

    return this.http
      .delete(endPoint, {
        headers: new HttpHeaders(headers),
        responseType: "text",
      })
      .toPromise();
  }
}

export const environment = {
  production: false,
  baseUrl: "https://fullrein.azure-api.net/staging/",
  firebaseConfig: {
    apiKey: "AIzaSyAMZc6BZr5R5ETqF2o1BOsMADNIx4-KRmE",
    authDomain: "fullrein-97449.firebaseapp.com",
    projectId: "fullrein-97449",
    storageBucket: "fullrein-97449.appspot.com",
    messagingSenderId: "687126086679",
    appId: "1:687126086679:web:105c02da31d4058e8ac852",
  },
  blobConfig: {
    accountName: "fullreindocuments",
    containerName: "fullrein-mf-logos",
    sasToken:
      "sp=racwdl&st=2021-07-15T10:32:03Z&se=2030-07-15T18:32:03Z&spr=https&sv=2020-08-04&sr=c&sig=cyN%2FZ6ZX2BoeGOWk%2Fj%2BEigE%2Bfy8j7vLCQqTdWaKT%2B7s%3D",
  },
  stripePk:
    "pk_test_51ItHc6CVo3HVOKfw2tbwQENaCjmDouy4PVgxcJn1k2Fx2EQuSJRsrto3brJ5EV15tSwI3B6tuHZbb8AMNpTBx56n00EmmpVENt",
};

import { ToastService } from 'src/app/services/toast/toast.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  DepartmentAccess,
  MPAssignmentUsers,
} from 'src/app/BLL/users/models/users.model';
import { UserNotification } from 'src/app/BLL/users/models/users.model';

//Data Models
import { GetUsers } from './models/users.model';

//Endpoints
import { urls } from './endpoints/users.endpoints';
import { ApiService } from 'src/app/services/api/api.service';
import { friendlyErrorMessage } from 'src/app/helpers/error-message.helper';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private users: BehaviorSubject<GetUsers[]> = new BehaviorSubject<GetUsers[]>([]);
  private MPAssignUsers: BehaviorSubject<MPAssignmentUsers> =
    new BehaviorSubject<MPAssignmentUsers>(undefined);

  //Loading Observables
  usersLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  assignMPLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _apiService: ApiService,
    private http: HttpClient,
    private _toastService: ToastService
  ) {}

  /**
   * @returns all {is_active=true} Medical Facility Users.
   */
  GetUsers(
    accessToken: string,
    page?: number,
    limit?: number,
    search?: string,
    sortBy?: string,
    sortType?: string,
    user_role?: string,
    medical_facility_id?: string
  ) {
    // let queryString =
    //   `?page=${page}&limit=${limit}&active_users=true` +
    //   ((search ? `&search=${search}` : "") +
    //     (sortBy ? `&sortBy=${sortBy}` : "") +
    //     (sortType ? `&sortType=${sortType}` : "") +
    //     (user_role ? `&user_role=${user_role}` : "") +
    //     (medical_facility_id ? `&medical_facility_id=${medical_facility_id}` : ""));

    //#region Add query params dynamically

    let queryParams = {};

    queryParams['page'] = page;
    queryParams['limit'] = limit;
    queryParams['active_users'] = true;

    if (search) {
      queryParams['search'] = search;
    }

    if (sortBy) {
      queryParams['sortBy'] = sortBy;
    }

    if (sortType) {
      queryParams['sortType'] = sortType;
    }

    if (user_role) {
      queryParams['user_role'] = user_role;
    }

    if (medical_facility_id) {
      queryParams['medical_facility_id'] = medical_facility_id;
    }

    //#endregion Add query params dynamically

    // let endPoint = this._apiService.baseURL + urls.users + queryString;

    let endPoint = this._apiService.baseURL + urls.users;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    //Start Loader
    this.setIsLoading('users', true);

    this.http
      .get<GetUsers[]>(endPoint, {
        params: queryParams,
        headers: new HttpHeaders(headers),
      })
      .pipe(take(1))
      .subscribe((users) => {
        this.users.next(users);

        //End Loader
        this.setIsLoading('users', false);
      });
  }

  GetUser(accessToken: string, user_id: string): Promise<any> {
    let queryString = `?user_id=${user_id}`;
    let endPoint = this._apiService.baseURL + urls.users + queryString;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http.get(endPoint, { headers: new HttpHeaders(headers) }).toPromise();
  }

  // DeleteUser(accessToken: string, user_id: string) {
  //   let queryString = `?user_id=${user_id}`;
  //   let endPoint = this._apiService.baseURL + urls.users + queryString;

  //   let headers = {
  //     "Content-Type": "text",
  //     Authorization: accessToken,
  //   };

  //   return this.http
  //     .delete(endPoint, {
  //       headers: new HttpHeaders(headers),
  //       responseType: "text",
  //     })
  //     .toPromise();
  // }

  /**
   * Delete will only deactivate the user. Only an admin from the Staffing Portal can do a full delete.
   *
   * Staffing Portal admins can reactivate accounts upon request.
   *
   * @returns Promise<string>
   */
  DeleteUser(accessToken: string, user_id: string): Promise<string> {
    let queryString = `?user_id=${user_id}`;
    let endPoint = this._apiService.baseURL + urls.users + queryString;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    const body = {
      isActive: false,
    };

    return this.http
      .patch(endPoint, body, {
        headers: new HttpHeaders(headers),
        responseType: 'text',
      })
      .toPromise();
  }

  EditUser(accessToken: string, user_id, body, medical_facility_id?: string) {
    let queryString =
      `?user_id=${user_id}` +
      (medical_facility_id ? `&medical_facility_id=${medical_facility_id}` : '');
    let endPoint = this._apiService.baseURL + urls.users + queryString;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .patch(endPoint, body, {
        headers: new HttpHeaders(headers),
        responseType: 'text',
      })
      .toPromise();
  }

  GetMPAssignUser(
    accessToken: string,
    mp_assign: boolean = true,
    search_query: string,
    user_role: string,
    medical_facility_id: string,
    department_id: string,
    shift_start: string,
    shift_end: string,
    repeat_days: string
  ) {
    if (search_query.length === 0) {
      this.ClearAssignMP();
    } else {
      let queryString = `?mp_assign=${mp_assign}&search_query=${search_query}&user_role=${user_role}&medical_facility_id=${medical_facility_id}&department_id=${department_id}&shift_start=${shift_start}&shift_end=${shift_end}&repeat_days=${repeat_days}`;
      let endPoint = this._apiService.baseURL + urls.users + queryString;
      console.log(
        '🚀 ~ file: users.service.ts ~ line 82 ~ UsersService ~ GetMPAssignUser ~ endPoint',
        endPoint
      );

      let headers = {
        'Content-Type': 'application/json',
        Authorization: accessToken,
      };

      //Start Loader
      this.setIsLoading('assign_mp', true);

      return this.http
        .get<MPAssignmentUsers>(endPoint, { headers: new HttpHeaders(headers) })
        .pipe(take(1))
        .subscribe(
          (users) => {
            this.MPAssignUsers.next(users);

            //End Loader
            this.setIsLoading('assign_mp', false);
          },
          (err) => {
            this._toastService.presentToast(
              friendlyErrorMessage(err, err.statusText),
              'danger'
            );
            //End Loader
            this.setIsLoading('assign_mp', false);
          }
        );
    }
  }

  ClearAssignMP() {
    this.MPAssignUsers.next({});
  }

  getUserNotifications(
    accessToken: string,
    notification_type: string,
    medical_facility_id: string,
    department_id?: string
  ): Promise<UserNotification[]> {
    if (!notification_type || !medical_facility_id) {
      return;
    }

    const queryString = `?notification_type=${notification_type}&medical_facility_id=${medical_facility_id}&department_id=${department_id}`;
    const endPoint = this._apiService.baseURL + urls.users + queryString;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .get<UserNotification[]>(endPoint, { headers: new HttpHeaders(headers) })
      .toPromise();
  }

  setUserNotifications(
    accessToken: string,
    notification_type: string,
    medical_facility_id: string,
    user_ids: string,
    department_id?: string
  ) {
    const queryString = `?notification_type=${notification_type}&medical_facility_id=${medical_facility_id}&department_id=${department_id}&user_ids=${user_ids}`;
    const endPoint = this._apiService.baseURL + urls.users + queryString;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .post(endPoint, null, {
        headers: new HttpHeaders(headers),
        responseType: 'text',
      })
      .toPromise();
  }

  getDepartmentAccess(
    accessToken: string,
    user_id: string,
    medical_facility_id: string
  ): Promise<DepartmentAccess> {
    if (!user_id || !medical_facility_id) {
      return;
    }

    const queryString = `?department_access=${user_id}&medical_facility_id=${medical_facility_id}`;
    const endPoint = this._apiService.baseURL + urls.users + queryString;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .get<DepartmentAccess>(endPoint, { headers: new HttpHeaders(headers) })
      .toPromise();
  }

  setDepartmentAccess(
    accessToken: string,
    user_id: string,
    medical_facility_id: string,
    department_ids: string
  ) {
    const queryString = `?department_access=${user_id}&medical_facility_id=${medical_facility_id}&departments=${department_ids}`;
    const endPoint = this._apiService.baseURL + urls.users + queryString;

    const headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .post(endPoint, null, {
        headers: new HttpHeaders(headers),
        responseType: 'text',
      })
      .toPromise();
  }

  public get users$(): Observable<GetUsers[]> {
    return this.users.asObservable();
  }

  public get assign_mp_users$(): Observable<MPAssignmentUsers> {
    return this.MPAssignUsers.asObservable();
  }

  //#endregion Getters for Observables & #region Loading Manager

  public get loader_users$(): Observable<boolean> {
    return this.usersLoading.asObservable();
  }

  public get loader_assign_mp_users$(): Observable<boolean> {
    return this.assignMPLoading.asObservable();
  }

  public setIsLoading(type: string, value: boolean) {
    switch (type) {
      case 'users':
        this.usersLoading.next(value);
      case 'assign_mp':
        this.assignMPLoading.next(value);
    }
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BlobStorageService } from 'src/app/services/blob-storage/blob-storage.service';
import { BehaviorSubject } from 'rxjs';

//Data Models
import {
  MedicalFacility,
  GetMedicalFacility,
  medicalFacilityStripe,
  medicalFacilityStripePaymentIntent,
  invite_user,
  UpdateMedicalFacility,
  CreateMedicalFacilityResponse,
  MedicalFacilityCards,
} from './models/medical-facility.model';

//Endpoints
import { urls } from './endpoints/medical-facility.endpoints';
import { map, take } from 'rxjs/operators';
import { setHttpParams } from 'src/app/helpers/http-params.helper';

@Injectable({
  providedIn: 'root',
})
export class MedicalFacilityService {
  /**
   * Medical Facility
   *
   * Used to disable left-side navigation until {GetMedicalFacility} has data.
   *
   * This fixes a race condition where you can click a link in the navigation without having the Medical Facility
   * data loaded yet.
   */
  mF = new BehaviorSubject<GetMedicalFacility>(null);
  medicalFacilityLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private _apiService: ApiService,
    private http: HttpClient,
    private blobStorageService: BlobStorageService
  ) {}

  getMedicalFacility(accessToken: string, medicalFacilityId: string) {
    let queryParam = `?medical_facility_id=${medicalFacilityId}`;
    let endPoint = this._apiService.baseURL + urls.medicalFacilityCreate + queryParam;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .get<GetMedicalFacility>(endPoint, { headers: new HttpHeaders(headers) })
      .toPromise();
  }

  getMF(accessToken: string, medicalFacilityId: string) {
    let queryParam = `?medical_facility_id=${medicalFacilityId}`;
    let endPoint = this._apiService.baseURL + urls.medicalFacilityCreate + queryParam;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    this.http
      .get<GetMedicalFacility>(endPoint, { headers: new HttpHeaders(headers) })
      .pipe(take(1))
      .subscribe(
        (facility: GetMedicalFacility) => {
          this.mF.next(facility);
          this.medicalFacilityLoading.next(false);
        },
        (_) => {
          this.medicalFacilityLoading.next(false);
        }
      );
  }

  getMedicalFacilities(accessToken: string) {
    let endPoint = this._apiService.baseURL + 'medical-facilities';

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };
    return this.http
      .get<GetMedicalFacility[]>(endPoint, {
        headers: new HttpHeaders(headers),
      })
      .toPromise();
  }

  createMedicalFacility(
    accessToken: string,
    body: MedicalFacility
  ): Promise<CreateMedicalFacilityResponse> {
    let endPoint = this._apiService.baseURL + urls.medicalFacilityCreate;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .post<CreateMedicalFacilityResponse>(endPoint, body, {
        headers: new HttpHeaders(headers),
      })
      .toPromise();
  }

  addMedicalFacilityPaymentMethod(accessToken: string, body: medicalFacilityStripe) {
    let endPoint = this._apiService.baseURL + urls.medicalFacilityCreate + urls.stripe;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .post(endPoint, body, {
        headers: new HttpHeaders(headers),
      })
      .toPromise();
  }

  getMedicalFacilityPaymentMethod(accessToken: string, medicalFacilityId: string) {
    let queryParam = `?medical_facility_id=${medicalFacilityId}`;
    let endPoint =
      this._apiService.baseURL + urls.medicalFacilityCreate + urls.stripe + queryParam;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http.get(endPoint, { headers: new HttpHeaders(headers) }).toPromise();
  }

  /**
   * @deprecated Use {getStripeMedicalFacilityCards} instead
   */
  getMedicalFacilityCards(accessToken: string, customer_id: string) {
    let queryParam = `?customer_id=${customer_id}`;
    let endPoint =
      this._apiService.baseURL + urls.medicalFacilityCreate + urls.stripe + queryParam;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http.get(endPoint, { headers: new HttpHeaders(headers) }).toPromise();
  }

  getStripeMedicalFacilityCards(accessToken: string, customer_id: string) {
    const queryParams: HttpParams = setHttpParams({
      customer_id: customer_id,
    });

    let endPoint = this._apiService.baseURL + urls.medicalFacilityCreate + urls.stripe;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .get<Array<MedicalFacilityCards>>(endPoint, {
        headers: new HttpHeaders(headers),
        params: queryParams,
      })
      .pipe(
        take(1),
        map((response: any) => {
          return response.data as Array<MedicalFacilityCards>;
        })
      )
      .toPromise();
  }

  createMedicalFacilityPaymentIntent(accessToken: string, customer_id, body) {
    let queryParam = `?customer_id=${customer_id}`;

    let endPoint =
      this._apiService.baseURL + urls.medicalFacilityCreate + urls.stripe + queryParam;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .post<medicalFacilityStripePaymentIntent>(endPoint, body, {
        headers: new HttpHeaders(headers),
      })
      .toPromise();
  }

  inviteUser(accessToken: string, body: invite_user) {
    let queryParam = '?invite_user=true';
    let endPoint = this._apiService.baseURL + urls.medicalFacilityCreate + queryParam;

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .post(endPoint, body, {
        headers: new HttpHeaders(headers),
        responseType: 'text',
      })
      .toPromise();
  }

  async updateFacility(
    accessToken: string,
    facility_id: string,
    body: UpdateMedicalFacility,
    logo?: File
  ) {
    let endPoint = this._apiService.baseURL + urls.medicalFacilityCreate;

    //#region Add query params dynamically
    let queryParams = {};

    if (logo) {
      body.logo = await this.uploadMedicalFacilityLogo(facility_id, logo);
    }

    if (facility_id) queryParams['medical_facility_id'] = facility_id;
    //#endregion Add query params dynamically

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .patch(endPoint, body, {
        params: queryParams,
        headers: new HttpHeaders(headers),
        responseType: 'text',
      })
      .toPromise();
  }

  async updateProfile(
    accessToken: string,
    facility_id: string,
    body: UpdateMedicalFacility,
    logo: File
  ): Promise<any> {
    let endPoint = this._apiService.baseURL + urls.medicalFacilityCreate;

    //#region Add query params dynamically
    let queryParams = {};

    if (logo) {
      body.logo = await this.uploadMedicalFacilityLogo(facility_id, logo);
    }

    if (facility_id) queryParams['medical_facility_id'] = facility_id;
    //#endregion Add query params dynamically

    let headers = {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    };

    return this.http
      .patch(endPoint, body, {
        params: queryParams,
        headers: new HttpHeaders(headers),
        responseType: 'text',
      })
      .toPromise();
  }

  async uploadMedicalFacilityLogo(
    medicalFacilityId: string,
    logoFile: File
  ): Promise<string> {
    if (logoFile && medicalFacilityId) {
      const fileName = `${medicalFacilityId}/${logoFile.name}`;

      await this.blobStorageService.uploadFiles(logoFile, fileName);

      return `${this.blobStorageService.url}${fileName}`;
    }

    return '';
  }
}

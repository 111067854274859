import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController) {}
  async presentToast(
    msg: string,
    color: string,
    position: 'bottom' | 'top' | 'middle' = 'bottom'
  ) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 5000,
      color: color,
      position,
    });
    toast.present();
  }
}

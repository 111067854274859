<ion-app>
  <ion-header *ngIf="routerAuthGuard" class="ion-hide-lg-down">
    <ion-toolbar class="bg-header">
      <ion-grid fixed>
        <ion-row>
          <ion-col size="4">
            <ion-img class="staffing_logo" src="assets/pages/dashboard/logo.png"></ion-img>
          </ion-col>
          <ion-col size="8">
            <ion-img class="notification_icon" src="assets/pages/dashboard/icon_notifications.png"
              (click)="openNotificationList($event)"></ion-img>
            <div *ngIf="(messageArray | async)?.length > 0" class="notification-badge">{{ (messageArray | async)?.length
              }}</div>
            <ion-badge *ngIf="
                userDb?.user_role === 'facility-admin' ||
                userDb?.user_role === 'facility-manager' ||
                userDb?.user_role === 'facility-scheduler'
              " class="role-badge">
              {{ userDb?.category_name }}
            </ion-badge>
            <ion-chip>
              <img src="../assets/pages/dashboard/icon_downarrow.png" (click)="openUserPopover($event)" />
              <ion-label>{{ userDb?.full_name }}</ion-label>
              <ion-avatar (click)="openUserPopover($event)">
                <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" />
              </ion-avatar>
            </ion-chip>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-header *ngIf="routerAuthGuard" mode="md" class="ion-hide-lg-up">
    <ion-toolbar class="sm-header">
      <ion-img slot="start" class="logo-sm" src="/assets/pages/login/logo_white.png" alt="Full Rein logo">
      </ion-img>
      <div slot="end" class="flex ion-align-items-center">
        <ion-avatar [hidden]="hideMobileAvatar" (click)="openUserPopover($event)" class="small ion-margin-end">
          <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" />
        </ion-avatar>
        <ion-menu-button></ion-menu-button>
      </div>
    </ion-toolbar>
  </ion-header>

  <ion-split-pane when="lg" contentId="main-content" *ngIf="routerAuthGuard">
    <ion-menu contentId="main-content" type="overlay" (ionWillClose)="onMenuEvent($event)"
      (ionWillOpen)="onMenuEvent($event)">
      <ion-content>
        <div class="ion-hide-lg-up">
          <div class="flex ion-justify-content-start ion-align-items-center mobile-content">
            <ion-avatar (click)="openUserPopover($event)" class="small ion-margin-end">
              <img src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png" />
            </ion-avatar>
            <div>
              <ion-text color="light" class="ion-text-nowrap username">{{ userDb?.full_name }}</ion-text>
              <br />
              <ion-badge *ngIf="
                    userDb?.user_role === 'facility-admin' ||
                    userDb?.user_role === 'facility-manager' ||
                    userDb?.user_role === 'facility-scheduler'
                  " class="role-badge">
                {{ userDb?.category_name }}
              </ion-badge>
            </div>
          </div>
          <hr class="hr-style" />
        </div>
        <ion-list>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages; let i = index">
            <ion-item *ngIf="checkIfRoleAccessRequired(p)" [disabled]="disabled" id="i-list" routerDirection="root"
              [routerLink]="[p.url]" detail="false" routerLinkActive="selected" lines="full">
              <!-- <ion-img slot="start" [src]="this.activatedAppUrl === p.url ? p.selectedIcon : p.icon"></ion-img> -->
              <ion-icon slot="start" [name]="p.iconName" color="light" size="small"></ion-icon>
              <ion-label>{{ p.title }}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>

  <div *ngIf="!routerAuthGuard">
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </div>
</ion-app>
import { Injectable } from "@angular/core";
import { ApiService } from "../../services/api/api.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

//Data Models
import { User } from "./models/signup.model";

//End points
import { urls } from "./endpoints/signup.endpoints";
@Injectable({
  providedIn: "root",
})
export class SignupService {
  constructor(private _apiService: ApiService, private http: HttpClient) {}

  // registerUser(
  //   userId: string,
  //   accessToken: string,
  //   user_role: string,
  //   full_name: string,
  //   email: string,
  //   gender: string,
  //   dob: Date,
  //   state: string,
  //   city: string,
  //   zipcode: string,
  //   display_picture: string,
  //   referralCode: string
  // ) {
  //   const body = {
  //     id: userId,
  //     user_role: user_role,
  //     full_name: full_name,
  //     email: email,
  //     gender: gender,
  //     dob: dob,
  //     state: state,
  //     city: city,
  //     zipcode: zipcode,
  //     display_picture: display_picture,
  //     ref_code: referralCode,
  //   };

  //   let endPoint = this._apiService.baseURL + urls.register;

  //   let headers = {
  //     'Content-Type': 'application/json',
  //     Authorization: accessToken,
  //   };

  //   return this.http
  //     .post(endPoint, body, {
  //       headers: new HttpHeaders(headers),
  //       responseType: 'text',
  //     })
  //     .toPromise();
  // }

  registerUser(accessToken, body, medical_facility_id?: string, departments?: string) {
    let queryString = medical_facility_id ? `?medical_facility_id=${medical_facility_id}` : "";

    //Append departments on signup if available
    if (departments) {
      queryString += `&departments=${departments}`;
    }

    let endPoint = this._apiService.baseURL + urls.register + queryString;

    let headers = {
      "Content-Type": "application/json",
      Authorization: accessToken,
    };

    return this.http.post(endPoint, body, { headers: new HttpHeaders(headers), responseType: "text" }).toPromise();
  }
}

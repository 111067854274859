import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';

import { SigninService } from '../../BLL/signin/signin.service';
// import { SignupService } from "../../BLL/signup/signup.service";
// import { DocumentService } from "../../BLL/documents/documents.service";
import { ToastService } from './../toast/toast.service';
import { SignupService } from 'src/app/BLL/signup/signup.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Save logged in user data
  userData: any;
  // Get user's Access Token
  accessToken: string;
  // Loader Subscription
  authIsLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private zone: NgZone,
    private _signInService: SigninService,
    private _signUpService: SignupService,
    // private _documentService: DocumentService,
    private _toastService: ToastService
  ) {
    // Saving user data in localstorage when logged in and setting up null when logged out
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        // console.log(
        //   "User in Local Storage",
        //   JSON.parse(localStorage.getItem("user"))
        // );
      } else {
        localStorage.setItem('user', null);
        localStorage.setItem('user_db', null);
        localStorage.setItem('medical_facility', null);
        // console.log(
        //   "User in Local Storage",
        //   JSON.parse(localStorage.getItem("user"))
        // );
      }
    });
  }

  //=========================== SERVICE METHODS ================================//

  // Sign in with email/password
  signIn(email: string, password: string) {
    this.authIsLoading.next(true);
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.getAccessToken().then(() => {
          // Hit API to check if user against this ID exists
          this._signInService.getUser(result.user.uid, this.accessToken).then((response) => {
            // console.log(response);
            if (response.category_type === 'facility') {
              if (response.is_active === true) {
                this.authIsLoading.next(false);
                //set user db in local storage
                localStorage.setItem('user_db', JSON.stringify(response));
                // console.log("Logged In User", result.user);
                this.router.navigate(['/dashboard']);
              } else {
                this.authIsLoading.next(false);
                this._toastService.presentToast("you've been blocked !!", 'danger');
                this.signOut();
              }
            } else {
              this.authIsLoading.next(false);
              this._toastService.presentToast('Please enter credentials for a Facility User', 'danger');
              this.signOut();
            }
          });
        });
      })
      .catch((error) => {
        this.authIsLoading.next(false);
        // console.log(error.message);
        this._toastService.presentToast(error.message, 'danger');
      });
  }

  // Sign Up with email/password
  async signUp(email: string, password: string): Promise<any | null> {
    // try {
    this.authIsLoading.next(true);

    let result = await this.afAuth.createUserWithEmailAndPassword(email, password);

    if (result) {
      await this.getAccessToken();

      this.authIsLoading.next(false);
      // localStorage.setItem("access_token", this.accessToken);
      // localStorage.setItem("user_id", result.user.uid);
      return {
        uid: result.user.uid,
        token: this.accessToken,
      };
    }

    this.authIsLoading.next(false);

    return null;
    // } catch (error) {
    //   this.authIsLoading.next(false);
    //   console.log("Error", error.message);
    //   this._toastService.presentToast(error.message, "danger");
    // }

    // return this.afAuth
    //   .createUserWithEmailAndPassword(email, password)
    //   .then(async (result) => {
    //     await this.getAccessToken();
    // 	this.authIsLoading.next(false);
    //     localStorage.setItem("access_token", JSON.stringify(this.accessToken));
    //     localStorage.setItem("user_id", JSON.stringify(result.user.uid));
    //   })
    //   .catch((error) => {
    //     this.authIsLoading.next(false);
    //     console.log("Error", error.message);
    //     this._toastService.presentToast(error.message, "danger");
    //   });
  }

  // Google Auth for signin or signup
  // GoogleAuth(action: string, role?: string) {
  //   if (action === "signin") {
  //     return this.socialSignIn(new firebase.auth.GoogleAuthProvider());
  //   } else if (action === "signup") {
  //     return this.socialSignUp(new firebase.auth.GoogleAuthProvider(), role);
  //   }
  // }

  // Facebook Auth for signin or signup
  // FacebookAuth(action: string, role?: string) {
  //   if (action === "signin") {
  //     return this.socialSignIn(new firebase.auth.FacebookAuthProvider());
  //   } else if (action === "signup") {
  //     return this.socialSignUp(new firebase.auth.FacebookAuthProvider(), role);
  //   }
  // }

  // Auth logic to run auth providers for signin
  socialSignIn(provider) {
    this.authIsLoading.next(true);
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        this.getAccessToken().then(() => {
          // Hit API to check if user against this ID exists
          this._signInService
            .getUser(result.user.uid, this.accessToken)
            .then((res) => {
              // If user is not present in DB prompt to Sign Up
              if (res === null) {
                this._toastService.presentToast('Email not found, please Sign Up first', 'danger');
                this.zone.run(() => {
                  this.authIsLoading.next(false);
                  this.signOut();
                });
              } else {
                this.authIsLoading.next(false);
                // console.log("Signed In User", result.user);
                this.zone.run(() => {
                  this.router.navigate(['fullrein/tabs/dashboard']);
                });
              }
            })
            .catch((err) => {
              this.authIsLoading.next(false);
              // console.error("error", err.error);
            });
        });
      })
      .catch((error) => {
        this.authIsLoading.next(false);
        window.alert(error);
      });
  }

  // Auth logic to run auth providers for signup
  // socialSignUp(provider, role) {
  //   this.authIsLoading.next(true);
  //   return firebase
  //     .auth()
  //     .signInWithPopup(provider)
  //     .then((result) => {
  //       this.getAccessToken().then(() => {
  // Hit API to signup this user
  // this._signUpService
  //   .registerUser(
  //     result.user.uid,
  //     this.accessToken,
  //     role,
  //     result.user.displayName,
  //     result.user.email,
  //     "",
  //     new Date(0),
  //     "",
  //     "",
  //     ""
  //   )
  //   .then((response) => {
  //     this.authIsLoading.next(false);
  //     console.log("Signed Up User", result.user);
  //     // console.log('SignUp Api Response', response);
  //     this._toastService.presentToast(response, "success");
  //     this.zone.run(() => {
  //       this.router.navigate(["fullrein/tabs/dashboard"]);
  //     });
  //   })
  //   .catch((err) => {
  //     this.authIsLoading.next(false);
  // console.log('error', JSON.parse(err.error).message);
  //             this._toastService.presentToast(
  //               JSON.parse(err.error).message,
  //               "danger"
  //             );
  //           });
  //       });
  //     })
  //     .catch((error) => {
  //       this.authIsLoading.next(false);
  //       window.alert(error);
  //     });
  // }

  // Returns the accessToken of the loggedIn user
  async getAccessToken() {
    this.accessToken = await (await this.afAuth.currentUser).getIdToken(true);
  }

  // Reset Password
  async resetPassword(email: string): Promise<boolean> {
    try {
      await this.afAuth.sendPasswordResetEmail(email);

      return true;
    } catch (error) {
      this._toastService.presentToast(error.message || error, 'danger');
      return false;
    }
  }

  // Sign out
  async signOut(): Promise<void> {
    await this.afAuth.signOut();

    localStorage.removeItem('user');
    localStorage.removeItem('user_db');
    localStorage.removeItem('medical_facility');
    localStorage.removeItem('device_token');

    await this.router.navigate([''], { replaceUrl: true });
    window.location.reload();

    return;
  }
}

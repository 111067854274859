/**
 * Fn to get a friendly error message from an error object.
 *
 * @param error Any error object that needs a friendly error message.
 * @param defaultMessage Defaults to `An error occurred. Please try again.`. The default message to return if no error message is found.
 * @returns A friendly error message.
 */
export const friendlyErrorMessage = (
  error: any,
  defaultMessage = 'An error occurred. Please try again.'
): string => {
  let friendlyMessage = defaultMessage;

  if (error) {
    if (error.error) {
      if (error.error.message) {
        friendlyMessage = error.error.message;
      } else if (error.error.error) {
        friendlyMessage = error.error.error;
      }
    } else if (error.message) {
      friendlyMessage = error.message;
    }
  }

  return friendlyMessage;
};

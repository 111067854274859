import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from "@angular/fire/auth-guard";

// AngularFireAuthGuard Pipe to redirect logged in User to dashboard on empty route
const redirectLoggedInUser = () => redirectLoggedInTo(["/dashboard"]);

// AngularFireAuthGuard Pipe to redirect unauthorized User back to login page
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["/login"]);
const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./views/login/login.module").then((m) => m.LoginPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInUser },
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./views/signup/signup.module").then((m) => m.SignupPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInUser },
  },
  {
    path: "reset-password",
    loadChildren: () =>
      import("./views/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordPageModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInUser },
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./views/dashboard/dashboard.module").then(
        (m) => m.DashboardPageModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "jobs",
    loadChildren: () =>
      import("./views/jobs/jobs.module").then((m) => m.JobsPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "timesheet",
    loadChildren: () =>
      import("./views/timesheet/timesheet.module").then(
        (m) => m.TimesheetPageModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: "department",
    loadChildren: () =>
      import("./views/department/department.module").then(
        (m) => m.DepartmentPageModule
      ),
  },
  {
    path: "guides",
    loadChildren: () =>
      import("./views/guide/guide.module").then((m) => m.GuidePageModule),
  },
  {
    path: "users",
    loadChildren: () =>
      import("./views/users/users.module").then((m) => m.UsersPageModule),
  },
  {
    path: "signup-invitation/:mf_id/:role/:departments",
    loadChildren: () =>
      import("./views/signup-invitation/signup-invitation.module").then(
        (m) => m.SignupInvitationPageModule
      ),
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("./views/notifications/notifications.module").then(
        (m) => m.NotificationsPageModule
      ),
  },
  {
    path: "dnr",
    loadChildren: () =>
      import("./views/dnr/dnr.module").then((m) => m.DnrListPageModule),
  },
  {
    path: "price-caps",
    redirectTo: "price-caps/",
    pathMatch: "full",
  },
  {
    path: "price-caps/:department_id",
    loadChildren: () =>
      import("./views/price-caps/price-caps.module").then(
        (m) => m.PriceCapsPageModule
      ),
  },
  {
    path: 'facility-profile',
    loadChildren: () => import('./views/facility-profile/facility-profile.module').then( m => m.FacilityProfilePageModule),
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

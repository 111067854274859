import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicSelectableModule } from 'ionic-selectable';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './services/authentication/auth-interceptor/auth.interceptor';
import { AuthService } from './services/authentication/auth.service';
import { UserPopoverComponent } from './components/user-popover/user-popover.component';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NumbersOnlyDirective } from './directives/NumbersOnly/numbers-only.directive';

@NgModule({
  declarations: [
    AppComponent,
    UserPopoverComponent,
    NotificationListComponent,
    NumbersOnlyDirective,
  ],
  entryComponents: [UserPopoverComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'ios' }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    HttpClientModule,
    IonicSelectableModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

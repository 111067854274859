import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { doesUserHaveAccessToMFProfile } from 'src/app/helpers/profile-access.helper';

@Component({
  selector: 'app-user-popover',
  templateUrl: './user-popover.component.html',
  styleUrls: ['./user-popover.component.scss'],
})
export class UserPopoverComponent implements OnInit {
  //User dropdown action
  action: any;
  userHasAccessToMFProfile = false;

  constructor(private popCtrl: PopoverController) {}

  ngOnInit() {
    this.userHasAccessToMFProfile = doesUserHaveAccessToMFProfile();
  }

  async logOut() {
    this.action = 'logout';

    return this.popCtrl.dismiss(this.action);
  }

  async notifications() {
    this.action = 'notifications';

    return this.popCtrl.dismiss(this.action);
  }

  async goToFacilityProfile() {
    this.action = 'facility-profile';

    return this.popCtrl.dismiss(this.action);
  }
}

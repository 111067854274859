<ion-list class="ion-hide-lg-down large-screens">
  <ng-container *ngIf="userHasAccessToMFProfile">
    <ion-item button (click)="goToFacilityProfile()" detail="false" lines="full">
      <ion-icon slot="start" name="business-outline"></ion-icon>
      <ion-label>Facility Profile</ion-label>
    </ion-item>
  </ng-container>
  <ion-item button (click)="logOut()" detail="false" lines="none">
    <ion-icon slot="start" name="log-out-outline" class="logout-icon"></ion-icon>
    <ion-label>Logout</ion-label>
  </ion-item>
</ion-list>
<ion-list class="ion-hide-lg-up">
  <ion-item button (click)="notifications()" detail="false" lines="full">
    <ion-icon slot="start" name="chatbubble-ellipses-outline"></ion-icon>
    <ion-label>Notifications</ion-label>
  </ion-item>
  <ng-container *ngIf="userHasAccessToMFProfile">
    <ion-item button (click)="goToFacilityProfile()" detail="false" lines="full">
      <ion-icon slot="start" name="business-outline"></ion-icon>
      <ion-label>Facility Profile</ion-label>
    </ion-item>
  </ng-container>
  <ion-item button (click)="logOut()" detail="false" lines="none">
    <ion-icon slot="start" name="log-out-outline"></ion-icon>
    <ion-label>Logout</ion-label>
  </ion-item>
</ion-list>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  token: any;
  organization: string;

  constructor(private http: HttpClient) {}

  public baseURL: string = environment.baseUrl;

  public get userAuthToken(): string {
    const user: any = JSON.parse(localStorage.getItem('user'));
    return user.stsTokenManager.accessToken;
  }

  async get(url: string): Promise<Observable<Object>> {
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http.get(url, { headers: new HttpHeaders(headers) });
  }

  async post(url: string, body: Object): Promise<Observable<Object>> {
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http.post(url, body, { headers: new HttpHeaders(headers) });
  }
}

import { Component, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { NotificationsService } from 'src/app/BLL/notifications/notifications.service';
import { FcmService } from 'src/app/services/fcm/fcm.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import jwtDecode from 'jwt-decode';
// Moment Js
import * as moment from 'moment';
import { NotificationItem } from 'src/app/BLL/notifications/models/notifications.model';
import { NavigationExtras } from '@angular/router';
import { friendlyErrorMessage } from 'src/app/helpers/error-message.helper';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  userData: any;
  medicalFacilityId: string;
  isLoading: boolean = false;
  //Moment
  moment: any = moment;

  //Firebase notification
  messageArray: BehaviorSubject<Array<any>>;
  notifications: any;
  constructor(
    private fcmService: FcmService,
    private nav: NavController,
    private popCtrl: PopoverController,
    private _notificationsService: NotificationsService,
    private _toastService: ToastService
  ) {}

  async ngOnInit() {
    //Sync the behavior subject
    this.messageArray = this.fcmService.notificationArray;
    this.userData = JSON.parse(localStorage.getItem('user'));
    //Decode medical facility id from user token
    this.medicalFacilityId = jwtDecode(this.userData?.stsTokenManager?.accessToken)[
      'medical_facility_id'
    ];

    try {
      this.isLoading = true;
      this.notifications = await this._notificationsService.getNotifications(
        this.userData?.stsTokenManager?.accessToken,
        this.medicalFacilityId
      );
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this._toastService.presentToast(friendlyErrorMessage(error), 'danger');
    }
  }

  async clickNotification(item: NotificationItem, index: number) {
    try {
      this.isLoading = true;

      await this._notificationsService.deleteNotificationById(
        this.userData?.stsTokenManager?.accessToken,
        item.id
      );

      this.fcmService.clearNotification(index);

      if (item.data?.deeplink) {
        this.nav.navigateForward(item.data?.deeplink);
      } else {
        this.nav.navigateForward('/timesheet');
      }

      this.popCtrl.dismiss();
    } catch (error) {
      this._toastService.presentToast(friendlyErrorMessage(error), 'danger');
      this.popCtrl.dismiss();
    } finally {
      this.isLoading = false;
    }
  }

  viewAllNotifications() {
    this.fcmService.clearAllNotifications();

    let navigationExtras: NavigationExtras = {
      queryParams: {
        refreshToken: new Date().getTime(),
      },
    };

    this.nav.navigateForward('/notifications', navigationExtras);
    this.popCtrl.dismiss();
  }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { Navigation, NavigationExtras, NavigationStart, Router } from "@angular/router";
import {
  MenuController,
  NavController,
  PopoverController,
} from "@ionic/angular";
import { BehaviorSubject, Subscription } from "rxjs";

import { UserPopoverComponent } from "./components/user-popover/user-popover.component";
import { NotificationListComponent } from "./components/notification-list/notification-list.component";
import { AuthService } from "./services/authentication/auth.service";
import { FcmService } from "./services/fcm/fcm.service";
import { MedicalFacilityService } from "./BLL/medical-facility/medical-facility.service";
import { GetMedicalFacility } from "./BLL/medical-facility/models/medical-facility.model";

interface AppPages {
  title: string;
  url: string;
  /**
   * @deprecated
   * Use `iconName` instead.
   */
  icon?: string;
  /**
   * @deprecated
   * Use `iconName` instead.
   */
  selectedIcon?: string;
  iconName: string;
  /**
   * Indicates that an admin role is needed to access the page.
   */
  adminRoleNeeded?: boolean;
  /**
   * Indicates that a manager or admin role is needed to access the page.
   */
  managerRoleNeeded?: boolean;
}

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  //User session
  userDb: any;
  activatedAppUrl = "";
  hideMobileAvatar = false;

  public appPages: Array<AppPages> = [
    {
      title: "DASHBOARD",
      url: "/dashboard",
      icon: "assets/pages/dashboard/icon_dboard_inactive.png",
      selectedIcon: "assets/pages/dashboard/icon_dboard.png",
      iconName: "bar-chart-outline",
    },
    {
      title: "JOBS",
      url: "/jobs",
      icon: "assets/pages/dashboard/icon_jobs_inactive.png",
      selectedIcon: "assets/pages/dashboard/icon_jobs.png",
      iconName: "medkit-outline",
    },
    {
      title: "TIMESHEETS",
      url: "/timesheet",
      icon: "assets/pages/dashboard/icon_timesheets_inactive.png",
      selectedIcon: "assets/pages/dashboard/icon_timesheets.png",
      iconName: "time-outline",
    },
    {
      title: "DEPARTMENTS",
      url: "/department",
      icon: "assets/pages/department/icon_department_inactive.png",
      selectedIcon: "assets/pages/department/icon_department.png",
      iconName: "business-outline",
    },
    {
      title: "USERS",
      url: "/users",
      icon: "assets/pages/dashboard/icon_users_inactive.png",
      selectedIcon: "assets/pages/dashboard/icon_users.png",
      iconName: "people-outline",
    },
    {
      title: "GUIDES",
      url: "/guides",
      icon: "assets/pages/dashboard/icon_vidguides_inactive.png",
      selectedIcon: "assets/pages/dashboard/icon_vidguides.png",
      iconName: "document-text-outline",
    },
    {
      title: "DNR",
      url: "/dnr",
      icon: "assets/pages/dashboard/ban-outline-inactive.svg",
      selectedIcon: "assets/pages/dashboard/ban-outline.svg",
      iconName: "ban-outline",
    },
    {
      title: "PRICE CAPS",
      url: "/price-caps",
      iconName: "cog-outline",
      managerRoleNeeded: true,
    },
  ];

  //Firebase notification
  messageArray: BehaviorSubject<Array<any>>;

  subscription: Subscription;
  disabled = true;

  routerAuthGuard = false;

  constructor(
    private router: Router,
    private navCtrl: NavController,
    private _authService: AuthService,
    private popCtrl: PopoverController,
    private fcmService: FcmService,
    private medicalFacilityService: MedicalFacilityService,
    private menuCtrl: MenuController
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // this.activatedAppUrl = router.url.toString();
        // console.log(this.activatedAppUrl);

        this.routerAuthGuard =
          event.url !== "/" &&
          event.url !== "/login" &&
          event.url !== "/signup" &&
          event.url !== "/reset-password" &&
          !event.url.includes("/signup-invitation");
      }
    });
  }

  ngOnInit() {
    this.subscription = this.medicalFacilityService.mF.subscribe((facility) => {
      const medicalFacililty: GetMedicalFacility = JSON.parse(
        localStorage.getItem("medical_facility")
      );

      this.disabled = !facility && !medicalFacililty;
    });

    //Request permission to get notifications
    this.fcmService.requestPermission();
    //Receive the message
    this.fcmService.receiveMessage();
    //Sync the behavior subject
    this.messageArray = this.fcmService.notificationArray;
  }

  ngDoCheck() {
    if (JSON.parse(localStorage.getItem("user_db"))) {
      this.userDb = JSON.parse(localStorage.getItem("user_db"));
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onMenuEvent(event: any) {
    if (event.type === "ionWillOpen") {
      this.hideMobileAvatar = true;
    } else if (event.type === "ionWillClose") {
      this.hideMobileAvatar = false;
    }
  }

  //Open user dropdown
  async openUserPopover(ev: any) {
    const popover = await this.popCtrl.create({
      component: UserPopoverComponent,
      event: ev,
    });

    popover.onDidDismiss().then(async (action) => {
      if (action.data && action.data === "logout") {
        await this.menuCtrl.close();
        await this._authService.signOut();
      } else if (action.data && action.data === "notifications") {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            refreshToken: new Date().getTime(),
          },
        };

        await this.menuCtrl.close();

        this.navCtrl.navigateForward("/notifications", navigationExtras);
      } else if(action.data && action.data === "facility-profile"){
        if(await this.menuCtrl.isOpen){
          await this.menuCtrl.close();
        }
        
        this.navCtrl.navigateForward("/facility-profile");
      }
    });

    return await popover.present();
  }

  //Open notification dropdown
  async openNotificationList(ev: any) {
    const popover = await this.popCtrl.create({
      component: NotificationListComponent,
      cssClass: "notification-popover",
      event: ev,
    });

    // popover.onDidDismiss().then((action) => {
    //   if (action.data && action.data === "logout") {
    //     this._authService.signOut();
    //   }
    // });

    return await popover.present();
  }

  /**
   * Fetch the role to render on the portal UI
   */
  facilityRole() {
    let role = "";
    if (this.userDb?.user_role === "facility-admin") {
      role = "Admin";
    } else if (this.userDb?.user_role === "facility-manager") {
      role = "Manager";
    } else if (this.userDb?.user_role === "facility-scheduler") {
      role = "Scheduler";
    } else {
      role = this.userDb?.user_role;
    }

    return role;
  }

  /**
   * Check if the user has access to the page.
   *
   * @param page The page to check access for.
   * @returns A boolean indicating if the user has access to the page.
   */
  checkIfRoleAccessRequired(page: AppPages) {
    if (page.adminRoleNeeded) {
      return this.userDb?.user_role === "facility-admin";
    } else if (page.managerRoleNeeded) {
      return (
        this.userDb?.user_role === "facility-manager" ||
        this.userDb?.user_role === "facility-admin"
      );
    } else {
      return true;
    }
  }
}
